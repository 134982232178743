import { createApp } from 'vue'
import router from './router';
import App from './App.vue'

// Font awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser, faEnvelope, faMessage, faXmarkCircle } from "@fortawesome/free-regular-svg-icons";
import { faLeftLong, faRightLong, faAngleDown, faCheck, faXmark, faLock, faLocationDot, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faUser, faEnvelope, faMessage, faLeftLong, faRightLong, faAngleDown, faCheck, faXmark, faLock, faPhoneAlt, faLocationDot, faXmarkCircle);

// Sweet Alert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';




createApp(App).component("font-awesome-icon", FontAwesomeIcon).use(router).use(VueSweetalert2).mount('#app')
