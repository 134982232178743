<template>
    <div class="modal-container">
        <div class="modal-wrap">
            <div class="modal-header flex justify-between mb-3 lg:mb-5 gap-3">
                <div class="modal-title">
                    <h3 class="title-h3 no-margin">{{ titleModal}}</h3>
                </div>
                <div class="modal-close">
                    <div class="title-h3 no-margin cta-text cursor-pointer p-1" @click="closeModal">
                        <font-awesome-icon :icon="['far', 'circle-xmark']" />
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <slot name="modalBody"></slot>
            </div>
            <div class="modal-footer mt-3 lg:mt-5" v-if="showFooter">
                <slot name="modalFooter"></slot>
            </div>
        </div>
        <div class="modal-backdrop" @click="closeModal"></div>
    </div>
</template>

<script>
    export default {
        name: 'ModalComponent',
        props: {
            titleModal: String,
            hasFooter: Boolean,
        },
        data() {
            return {
                showFooter: this.hasFooter
            }
        }, 
        methods: {
            closeModal() {
                this.$emit("close-modal");
            }
        },
        
    }

</script>