<template>
  <header class="header">
    <div class="bg-blue w-full header-wrapper rounded-sm px-5 pt-5 lg:pt-0">
      <router-link :to="pathHome" class="bg-white flex justify-center items-center rounded-sm header-logo">
        <img src="../assets/img/logo/aksis.png" alt="Aksis, conseil en évolution professionnelle" />
      </router-link>
    </div>
  </header>
    
</template>

<script>
  import config from '@/config';
  export default {
    name: 'HeaderComponent',
    data() {
      return {
        pathHome : config.paths.home
      }
    }
  }
</script>

<style>
  .header {
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 100;
  }

  .header-logo {
    width: 170px;
    height: 80px;
  }

  .header-wrapper {
    height: 68px;
  }

  @media (min-width : 1024px) {
    .header {
      position: relative;
      z-index: 100;
      width: 100%;
    }

    .header-logo {
      width: 275px;
      position: relative;
      top: 25px;
      left: -35px;
      height: 110px;
      padding: 25px 20px;
    }

    .header-wrapper {
      height: 100px;
    }
  }

</style>