import { createRouter, createWebHistory } from "vue-router";
import HomePage from '@/views/HomePage.vue';
import QuestionsPage from '@/views/QuestionsPage.vue';
import ResultPage from '@/views/ResultPage.vue';
import PageNotFound from '@/views/PageNotFound.vue';
import TemplatePage from '@/views/TemplatePage.vue';
import PageRemerciement from '@/views/PageRemerciement.vue';
import config from "@/config";

const routes = [
    {
        name: "HomePage",
        path: config.paths.home,
        component: HomePage
    },
    {
        name: "QuestionsPage",
        path: config.paths.questions,
        component: QuestionsPage
    },
    {
        name: "ResultPage",
        path: config.paths.resultat,
        component: ResultPage
    },
    {
        name: "TemplatePage",
        path: config.paths.template,
        component: TemplatePage
    },
    { 
        path: "/:pathMatch(.*)*", 
        redirect: {name: "404"}
    },
    {
        name: "404",
        path: "/404",
        component: PageNotFound
    },
    {
        name: "PageRemerciement",
        path: config.paths.merci,
        component: PageRemerciement
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes 
})

export default router;