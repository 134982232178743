<template>
  <div class="w-full mt-[15px] md:mt-[50px] px-2 md:px-0 relative">
    <question-component 
    v-if="!loadingQuestions"  
    :questions="questions" 
    :random-index-questions="randomIndexQuestions" 
    />
  </div>
  <peoples-image-component />

</template>

<script>
import QuestionComponent from '@/components/QuestionComponent.vue';
import PeoplesImageComponent from '@/components/PeoplesImageComponent.vue';
import config from '@/config';

export default {
    name: 'QuestionPage',
    components: {
        QuestionComponent,
        PeoplesImageComponent,
    },
    data() {
        return {
            loadingQuestions: true,
            questions: [],

            randomIndexQuestions: []
        }
    },
    methods: {
        async getQuestions() {
            const requestOptions = {
                method: "POST",
                cors: 'no-cors',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            };

            const response = await fetch(config.fetchURL + 'getQuestions.php', requestOptions)
            const data = await response.json();

            return data;
        },

        setQuestions(questions) {
            this.questions = questions;

            //On crée un tableau d'index qui comporte le même nombre d'index que de questions
            var rangeIndex = [...Array(this.questions.length).keys()];

            //On va sélectionner des index au hasard pour les mettre dans un tableau qui constituera nos index de questions au hasard
            for (var i = 0; i < this.questions.length; i++) {
                //On prend un nombre au hasard dans le tableau range
                var randomIndexFromRange = Math.floor(Math.random() * rangeIndex.length);

                //Ça nous donne un index au hasard pour sélectionner une question au hasard
                this.randomIndexQuestions.push(rangeIndex[randomIndexFromRange]);

                //On retire l'index du tableau des index
                rangeIndex.splice(randomIndexFromRange, 1);
            }

            localStorage.setItem('questions', JSON.stringify(this.questions));
            localStorage.setItem('randomIndexQuestions', JSON.stringify(this.randomIndexQuestions));
        },
    },
    created() {
        if (!localStorage.getItem('questions') && !localStorage.getItem('randomIndexQuestions')) {
            this.getQuestions().then((questions) => {
                this.loadingQuestions = false;
                this.setQuestions(questions);
            });
        } else {
            //Récupération des questions dans le localStorage
            this.questions = JSON.parse(localStorage.getItem('questions'));
            this.randomIndexQuestions = JSON.parse(localStorage.getItem('randomIndexQuestions'));
            this.loadingQuestions = false;
        }
    },
}
</script>