<template>
    <div>
        <div v-if="Object.keys(score).length == 0" class="timeline">
            <div class="timeline-container">
                <div class="timeline-indicator" :style="'width: '+getWidthTimeline+'%'"></div>
                <div class="timeline-indicator timeline-done" :style="'width: '+getWidthTimelineDone+'%'"></div>
            </div>
        </div>
        <!-- Si le questionnaire n'a pas déjà été soumis -->
        <div v-if="Object.keys(score).length == 0" class="grid grid-cols-12 gap-5 md:gap-0 pb-5 relative">
            <div class="md:bg-white col-span-12 mb-[102px] md:mb-[50px] md:p-[45px] py-5 grid grid-cols-12 md:gap-[45px] relative">
                <img v-if="!calculatingResult" src="../assets/img/illu/arrow-orange-1.png" class="fleche-1 illu-question" alt="Flèche orange" />
                <img v-if="!calculatingResult" src="../assets/img/illu/arrow-yellow-1.png" class="fleche-2 illu-question" alt="Flèche jaune" />

                <div class="col-span-12">
                    <h1 class="title-question mb-[40px] md:text-center select-none md:mb-0">{{ question.question }}</h1>
                </div>
                <div class="col-span-12">
                    <div class="flex justify-center">
                        <div
                            class="w-full flex space-y-[40px] md:space-y-0 flex-col md:flex-row md:max-w-[500px] justify-between items-center">
                            <div v-for="index in 5" :key="index" class="w-full md:w-auto flex items-center row-answer select-none" @click="selectAnswer(index)">
                                <div class="flex w-[38px] justify-center">
                                    <div 
                                    @mouseenter="setTipsReponse(response[index - 1] )"
                                    @mouseleave="hideTipsReponse()"
                                    :class="'circle-answer lg:cursor-pointer circle-answer-' + index + (question.completed && question.answer == index ? ' circle-selected' : '')">
                                    </div>
                                </div>
                                <p class="md:hidden ml-[15px]">{{ response[index - 1] }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-span-12 hidden md:block">
                    <div class="w-full relative p-2">
                        <p class="para-tips relative -z-1 px-5 text-center select-none">
                            Positionnez vous sur cette échelle, de "Pas du tout d'accord" à "Tout à fait d'accord".
                        </p>
                        <div 
                        v-if="bShowTips"
                        class="absolute top-0 z-8 left-0 h-full w-full flex justify-center items-center bg-white container-selected-answer">
                            <p class="text-center selected-answer">{{ activeReponse }}</p>
                        </div>
                    </div>
                </div>
                <div class="absolute right-2 lg:right-4 bottom-2 lg:bottom-4 hidden md:block">
                    <div class="flex justify-center">
                        <div class="grid grid-cols-2">
                            <div class="h-[44px] w-[44px] current-page flex justify-center font-bold items-center">
                                <p>{{ currentPage }}</p>
                            </div>
                            <div
                                class="h-[44px] w-[44px] flex justify-center font-bold items-center rounded-sm  bg-blue">
                                <p class="text-white">{{ nbQuestion }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="anim-question-bg z-10">
                    <div class="question-bg bg-orange" style="--i : 1"></div>
                    <div v-if="calculatingResult" class="question-bg bg-blue" style="--i : 2"></div>
                    <div v-if="calculatingResult" class="question-bg bg-yellow" style="--i : 3"></div>
                    <div v-if="calculatingResult" class="question-bg bg-orange" style="--i : 4"></div>
                    <div v-if="calculatingResult" class="question-bg bg-blue" style="--i : 5"></div>
                    <div v-if="calculatingResult" class="question-bg bg-yellow" style="--i : 6"></div>
                </div>
            </div>
            <!-- NAVIGATION DESKTOP -->
            <div class="col-span-12 hidden md:block">
                <!-- Classe "flex-row-reverse" si bouton "Précédent" non affiché (bouton Suivant tout à droite) -->
                <div
                    class="flex justify-between"
                    :class="{ 'flex-row-reverse': currentPage <= 1 }" >
                    <button v-if="currentPage > 1" class="btn btn-primary justify-self-start" @click="prevQuestion">
                        Question précédente
                    </button>
                    <button v-if="currentPage < numberPagesCompleted" class="btn btn-primary justify-self-end" @click="nextQuestion">
                        Question suivante
                    </button>
                </div>
            </div>
            <div class="col-span-12 lg:col-span-5 xl:col-span-5 fixed-col md:hidden">
                <div class="grid grid-cols-12 gap-3">
                    <div
                        v-if="currentPage > 1"
                        class="col-span-4"
                    >
                        <div class="flex justify-center">
                            <button class="btn btn-yellow h-[54px] w-[54px] bg-yellow lg:mt-5" @click="prevQuestion">
                                <font-awesome-icon :icon="['fa', 'left-long']" />
                            </button>
                        </div>
                    </div>
                    <div
                        class="col-span-4"
                        :class="[(currentPage > 1) ? '' : 'col-start-5']"
                    >
                        <div class="flex justify-center">
                            <div class="grid grid-cols-2">
                                <div class="h-[54px] w-[54px] flex justify-center font-bold items-center">
                                    <p>{{ currentPage }}</p>
                                </div>
                                <div
                                    class="h-[54px] w-[54px] flex justify-center font-bold items-center rounded-sm  bg-blue">
                                    <p class="text-white">{{ nbQuestion }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="currentPage < numberPagesCompleted"
                        class="col-span-4"
                    >
                        <div class="flex justify-center">
                            <button class="btn btn-orange h-[54px] w-[54px] bg-orange lg:mt-5" @click="nextQuestion">
                                <font-awesome-icon :icon="['fa', 'right-long']" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Si le questionnaire a déjà été soumis -->
        <div v-else>
            <div
                class="md:bg-white col-span-12 mb-[102px] md:mb-[50px] md:p-[45px] grid grid-cols-12 md:gap-[45px] relative">
                <img src="../assets/img/illu/arrow-orange-1.png" class="fleche-1 illu-question" alt="Flèche orange" />
                <img src="../assets/img/illu/arrow-yellow-1.png" class="fleche-2 illu-question" alt="Flèche jaune" />

                <div class="col-span-12">
                    <h1 class="title-question mb-[15px] md:text-center select-none md:mb-0">Vous avez déjà répondu à ce questionnaire.</h1>
                </div>

                <div class="col-span-12 flex justify-center">
                    <div class="w-full lg:w-2/3 xl:w-1/2">
                        <div class="grid grid-cols-12 items-end gap-3">
                            <div class="col-span-5 md:col-span-4">
                                <div class="bloc-image bloc-yellow" style="--i : 1">
                                    <img src="../assets/img/photos/aksis_bdc_homme1.png" alt="Homme joyeux" />
                                </div>
                            </div>
                            <div class="col-span-7 md:col-span-4">
                                <div class="bloc-image bloc-orange" style="--i : 2">
                                    <img src="../assets/img/photos/aksis_bdc_femme3.png" alt="Femme heureuse" />
                                </div>
                            </div>
                            <div class="col-span-12 md:col-span-4" style="--i : 3">
                                <div class="bloc-image bloc-blue">
                                    <img src="../assets/img/photos/aksis_bdc_homme2.png" alt="Homme pensif" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- NAVIGATION DESKTOP -->
            <div class="col-span-12 hidden md:block">
                <div class="flex justify-center gap-5">
                    <router-link :to="pathResult">
                        <button class="btn btn-primary">
                            Voir mes résultats
                        </button>
                    </router-link>
                    <router-link :to="pathQuestions">
                        <button class="btn btn-orange h-[54px] bg-orange" @click="recommencerTest">
                            Recommencer le test
                        </button>
                    </router-link>
                </div>
            </div>
            <!-- NAVIGATION MOBILE -->
            <div class="col-span-12 lg:col-span-5 xl:col-span-5 fixed-col md:hidden">
                <div class="grid grid-cols-12 gap-3">
                    <div class="col-span-12">
                        <div class="flex justify-center flex-wrap gap-5 lg:mt-5">
                            <router-link :to="pathResult">
                                <button class="btn btn-orange h-[54px] bg-orange">
                                    Voir mes résultats
                                </button>
                            </router-link>
                            <button class="btn btn-orange h-[54px] bg-orange" @click="recommencerTest">
                                Recommencer le test
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import config from '@/config';

import manageLogStatDiagnostic from "@/fonctions";

export default {
    name: 'QuestionComponent',
    props: {
        questions: Object,
        randomIndexQuestions: Object,
    },
    data() {
        return {
            question: [],
            score: {},
            answers: [],
            currentPage: undefined,
            numberPagesCompleted: undefined,
            nbQuestion: undefined,
            bShowPaginationMobile: false,
            bAnswerSelected: false,
            bShowTips: false,
            calculatingResult: false,
            aPaginationDesktop: [],
            response: [
                "Pas du tout d'accord",
                "Plutôt pas d'accord",
                "Neutre",
                "Plutôt d'accord",
                "Tout à fait d'accord",
            ],
            activeReponse: 'Neutre',
            bShowPaginationDesktop : false,
            pathResult : config.paths.resultat,
            pathQuestions : config.paths.questions,
        }
    },

    created() {
        //Si le localStorage est initialisé, on prend la page du localStorage, sinon la page 1
        this.numberPagesCompleted = localStorage.getItem('numberPagesCompleted') ? localStorage.getItem('numberPagesCompleted') : 1;
        this.currentPage = this.numberPagesCompleted;
        this.setActiveQuestion();
        this.initAnswers();
        this.initScore();

        this.nbQuestion = this.questions.length;
    },

    methods: {
        setTipsReponse(tips) {
            this.activeReponse = tips;
            this.bShowTips = true;
        },
        hideTipsReponse() {
            
            if(!this.bAnswerSelected) {
                this.activeReponse = "";
                this.bShowTips = false;
            }
        },
        setActiveQuestion() {
            //On affiche la question en fonction de la page sur laquelle on se trouve
            var randomIndex = this.randomIndexQuestions[this.currentPage - 1];
            this.question = this.questions[randomIndex];
            this.bAnswerSelected = false;

            //Si une réponse a déjà été entrée, on la remet dans this.question pour affichage
            if(this.answers[this.currentPage - 1]) {
                this.question.answer = this.answers[this.currentPage - 1];
                this.question.completed = true;
                this.bAnswerSelected = true;
                this.activeReponse = this.response[this.question.answer - 1];
                this.bShowTips = true;
            } else {
                this.bShowTips = false;
            }
        },

        selectAnswer(index) {
            //On stocke la réponse sélectionnée dans la question (au clic d'une réponse)
            this.question.answer = index; //On récupère l'index de la réponse sélectionnée
            this.question.completed = true;

            //Si cette question est la dernière, alors on calcule le score, et on le stocke dans le localStorage
            if(this.currentPage == this.nbQuestion) {
                this.calculateScoreAndRedirectResults();
            }
            var bAnswerWasSelected = this.bAnswerSelected;
            this.bAnswerSelected = true;
            this.activeReponse = this.response[index - 1];

            if(!bAnswerWasSelected) {
                this.nextQuestion();
            }
        },

        initAnswers() {
            //On récupère les réponses envoyées si elles existent dans le localStorage
            if (localStorage.getItem('answers')) {
                this.answers = JSON.parse(localStorage.getItem('answers'));
            }
        },

        initScore() {
            //On récupère les réponses envoyées si elles existent dans le localStorage
            if (localStorage.getItem('score')) {
                this.score = JSON.parse(localStorage.getItem('score'));
            }
        },

        nextQuestion() {
            if (!this.question.completed) {
                this.$swal({
                    "text": "Veuillez sélectionner une réponse.",
                    ...config.swalOptions
                });
                return
            }

            if (this.currentPage === this.nbQuestion) return
            this.selectPage(parseInt(this.currentPage) + 1);
        },

        prevQuestion() {
            if (this.currentPage === 1) return
            this.selectPage(parseInt(this.currentPage) - 1);
        },

        selectPage(numPage) {
            //On stocke la réponse sélectionnée
            this.setAnswer();

            this.currentPage = numPage;

            //On stocke le numéro de page le plus avancé auquel l'utilisateur a eu accès (au cas où il soit retourné sur des questions précédentes)
            if (this.currentPage >= this.numberPagesCompleted) {
                this.numberPagesCompleted = this.currentPage;
                localStorage.setItem('numberPagesCompleted', numPage);
            }

            //On affiche la question
            this.setActiveQuestion();

            // On cache la pagination mobile
            this.bShowPaginationMobile = false;
            this.bShowPaginationDesktop = false;
        },

        setAnswer() {
            if (localStorage.getItem('answers') === null) {
                manageLogStatDiagnostic("debut_diagnostic");
            }
            
            //On stocke la réponse sélectionnée
            if(this.question.answer) {
                this.answers[this.currentPage - 1] = this.question.answer;
                localStorage.setItem('answers', JSON.stringify(this.answers));
            }
        },

        setScore() {
            //On calcule le score, et on le stocke dans le localStorage
            this.randomIndexQuestions.forEach((value, key) => {
                var nom_dimension = this.questions[value].nom_dimension;
                var id_dimension = this.questions[value].id_dimension;

                if (this.score[nom_dimension]) {
                    this.score[nom_dimension].dimension = id_dimension;
                    this.score[nom_dimension].nb_questions += 1;
                    this.score[nom_dimension].score_total += this.answers[key];
                } else {
                    this.score[nom_dimension] = {
                        'dimension': id_dimension,
                        'nb_questions': 1,
                        'score_total': this.answers[key]
                    };
                }
            });

            localStorage.setItem('score', JSON.stringify(this.score));
        },

        calculateScoreAndRedirectResults() {
            setTimeout(() => {
                this.setAnswer();
                this.setScore();
            }, 2000)

            this.animateRedirectionResultPage();
        },

        animateRedirectionResultPage() {
            this.calculatingResult = true;
            setTimeout(() => {
                this.$router.push({ name: 'ResultPage' })
            }, 2000)
        },

        recommencerTest() {
            const lsToRemove = ["score", "answers", "numberPagesCompleted", "randomIndexQuestions", "questions", "id_log_stat_diagnostic"];
            
            manageLogStatDiagnostic("interruption_diagnostic");
            
            for (const ls of lsToRemove) {
                localStorage.removeItem(ls);
            }

            // Reload the page
            this.$router.go()
        }
    },

    computed : {
        getWidthTimeline() {
            return (this.currentPage * 100) / this.nbQuestion;
        },
        getWidthTimelineDone() {
            return (this.numberPagesCompleted * 100) / this.nbQuestion;
        }
    }
}
</script> 
  


