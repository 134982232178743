<template>
  <div class="fixed bottom-0 right-[50px] hidden lg:flex img-translate-y-in pointer-events-none">
    <div class="container-image-groupe-personne">
      <img src="../assets/img/photos/aksis_bdc_groupe.png" class=" image-groupe-personne" alt="Groupe de personnes" />
      <div class="circle circle-1 bg-orange"></div>
      <div class="circle circle-2 bg-yellow"></div>
      <div class="circle circle-3 bg-yellow"></div>
      <div class="illu-img illu-img-1">
        <img src="../assets/img/illu/arrow-yellow-1.png" alt="Flèche jaune" />
      </div>
      <div class="illu-img illu-img-2">
        <img src="../assets/img/illu/arrow-orange-1.png" alt="Flèche orange" />
      </div>
      <div class="illu-img illu-img-3">
        <img src="../assets/img/illu/arrow-yellow-2.png" alt="Flèche jaune" />
      </div>
      <div class="illu-img illu-img-4">
        <img src="../assets/img/illu/arrow-orange-2.png" alt="Flèche orange" />
      </div>
    </div>
    </div>
</template>

<script>
export default {
  name: 'PeoplesImageComponent',
}
</script> 

<style> 

  .image-groupe-personne {
    width: clamp(350px, 40vw, 700px);
    position: relative;
    z-index: 10;
    user-select: none;
  }
  

  .circle {
    position: absolute;
    height: 25px;
    width: 25px;
    border-radius: 50%;
  }

  .illu-img {
    position: absolute;
    user-select: none;
  }

  .illu-img-1 {
    top: 5px;
    left: -35px;
    width: 120px;
    height: 120px;
  }

  .illu-img-2 {
    top: -10px;
    right: 10%;
    width: 100px;
    height: 100px;
  }

  .illu-img-3 {
    top: 0;
    right: 40%;
    width: 120px;
    height: 120px;
  }

  .illu-img-4 {
    bottom: 0;
    right: -3%;
    width: 100px;
    height: 100px;
  }

  .circle-1 {
    top: 0;
    left: 20%;
    width: 18px;
    height: 18px;
  }

  .circle-2 {
    top: 20px;
    left: 30%;
    width: 15px;
    height: 15px;
  }

  .circle-3 {
    top: -5px;
    right: 30%;
    width: 22px;
    height: 22px;
  }

  @media (max-height : 850px) {
    .container-image-groupe-personne {
      transform: scale(.8);
      transform-origin: bottom right;
    }
  }

  @media (max-height : 780px) {
    .container-image-groupe-personne {
      transform: scale(.6);
      transform-origin: bottom right;
    }
  }

  @media (max-height : 760px) {
    .container-image-groupe-personne {
      display: none;
    }
  }


</style>


