<template>
  <div class="grid grid-cols-12 gap-5 py-5">
    <div class="col-span-12 md:col-span-6 mb-5">
      <p class="template-title text-orange">Fonts</p>
      <h2 class="title-h2">Titre H2 - Lorem ipsum dolor sit.</h2>
      <h2 class="title-h3">Titre H3 - Lorem ipsum dolor sit.</h2>
      <p class="para-text">Paragraphe - Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ut aut deleniti amet esse commodi provident laborum vel iusto architecto similique? Quod, animi! Alias et adipisci voluptatem obcaecati similique sint recusandae nisi autem aspernatur accusamus sunt corrupti facere deleniti officiis reprehenderit saepe hic nostrum numquam fugiat iste neque facilis, possimus esse? Neque, repellat?</p>
      <p class="para-text font-bold">Paragraphe bold - Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit veritatis quisquam nemo!</p>
    </div>
    <div class="col-span-12 md:col-span-6">
      <p class="template-title text-blue">Boutons</p>
      <button class="btn btn-primary">
        Je commence mon diagnostic
      </button>
      <p class="template-title text-blue mt-5">Formulaire</p>
      <div class="bg-white p-4 rounded w-full">
        <form class="grid grid-cols-12 gap-3 md:gap-5">
          <div class="col-span-12 md:col-span-6">
            <label class="form-label">Votre nom*</label>
            <div class="form-row form-success">
              <input type="text" class="form-input" />
              <div class="form-picto">
                <font-awesome-icon :icon="['far', 'user']" />
              </div>
            </div>
          </div>
          <div class="col-span-12 md:col-span-6">
            <label class="form-label">Votre prénom*</label>
            <div class="form-row form-error">
              <input type="text" class="form-input" />
              <div class="form-picto">
                <font-awesome-icon :icon="['far', 'user']" />
              </div>
            </div>
          </div>
          <div class="col-span-12">
            <label class="form-label">Votre adresse email*</label>
            <div class="form-row">
              <input type="text" class="form-input" />
              <div class="form-picto">
                <font-awesome-icon :icon="['far', 'envelope']" />
              </div>
            </div>
          </div>
          <div class="col-span-12">
            <label class="form-label">Votre message</label>
            <div class="form-row row-textarea">
              <textarea class="form-input" cols="30" rows="10"></textarea>
              <div class="form-picto">
                <font-awesome-icon :icon="['far', 'message']" />
              </div>
            </div>
          </div>
  
        </form>
      </div>
    </div>
    <div class="col-span-12 md:col-span-6">
      <p class="template-title text-blue">Images</p>
      
      <div class="grid grid-cols-12 items-end gap-3">
        <div class="col-span-5 lg:col-span-4">
          <div class="bloc-image bloc-yellow">
            <img src="../assets/img/photos/aksis_bdc_homme1.png" alt="Homme joyeux" />
          </div>
        </div>
        <div class="col-span-7 lg:col-span-4">
          <div class="bloc-image bloc-orange">
            <img src="../assets/img/photos/aksis_bdc_femme3.png" alt="Femme heureuse" />
          </div>
        </div>
        <div class="col-span-12 lg:col-span-4">
          <div class="bloc-image bloc-blue">
            <img src="../assets/img/photos/aksis_bdc_homme2.png" alt="Homme pensif" />
          </div>
        </div>
      </div>
    </div>
  </div>
    
</template>

<script>
  // import FontAwesomeIcon from '@fortawesome/fontawesome-svg-core';
  export default {
    name: 'TemplatePage',
  }
</script>

<style>
  .template-title {
    font-size: 1.8rem;
    margin-bottom: 30px;
  }
</style>