<template>
	<div class="scroll-wrapper py-5">
		<h2 class="title-h2">Les résultats de votre diagnostic</h2>

		<div class="grid grid-cols-12 gap-5 pt-[10px] mb-[35px] lg:mb-[50px] lg:gap-[50px] select-none">
			<div class="col-span-6 sm:col-span-4 lg:col-span-4">
				<img src="../assets/img/photos/Perso6.png" style="--i : 1" class="w-full max-w-[180px] img-scale-in mx-auto"
					alt="Femme utilisant son téléphone" />
			</div>
			<div class="col-span-6 sm:col-span-4 lg:col-span-4">
				<img src="../assets/img/photos/Perso3.png" style="--i : 2" class="w-full max-w-[180px] img-scale-in mx-auto"
					alt="Homme souriant" />
			</div>
		</div>
		<div class="flex flex-col space-y-[50px]">
			<div v-for="(resultat, index) in this.resultats_reponses" :key="index">
				<div class="title-resultat" v-bind:class="(index % 2 == 0) ? 'title-orange': 'title-blue' ">
					<h3>{{resultat.titre_resultat}} :</h3>
					<h4 class="title-h4" v-html="resultat.description_dimension"></h4>
				</div>
				<p class="para-text">{{ resultat.resultat_description }}</p>
				<div v-if="resultat.points_forts.length">
					<p class="title-points-fort">Vos points fort :</p>

					<ul class="liste-points-forts">
						<li v-for="(point_fort, index) in resultat.points_forts" :key="index"
							class="point-fort-item point-fort-orange">
							<p class="para-text mb-2">{{ point_fort.description }}</p>
						</li>
					</ul>
				</div>
			</div>
			<div class="lg:hidden" id="recommandation-mobile">
				<h3 class="title-recommandation-mobile">Notre recommandation pour vous</h3>
				<p class="para-text text-blue font-[500] mb-[20px]">{{this.recommandation.titre}} </p>
				<div class="mb-[5px]">
					<div class="para-text">
						{{ this.recommandation.description }}
					</div>
				</div>
				<p class="mt-5">
					<a :href="this.recommandation.lien_cta" class="cta-text" target="_blank">{{ this.recommandation.description_lien_cta }} {{ this.recommandation.titre }}</a>
				</p>
			</div>
			<div class="flex justify-center">
				<button class="btn btn-orange h-[54px] bg-orange" @click="recommencerTest">
					Recommencer le test
				</button>
			</div>
		</div>
	</div>
</template>

<script>

import config from '@/config';

import manageLogStatDiagnostic from "@/fonctions";

export default {
	name: 'ResultDetail',
	props: {
		recommandationProps: Object,
		resultatsProps: Array
	},
	data() {
		return {
			resultats_reponses: this.resultatsProps,
			recommandation : this.recommandationProps,
			pathQuestions : config.paths.questions,
		}
	},
	methods: {
		recommencerTest() {
			const lsToRemove = ["score", "answers", "numberPagesCompleted", "randomIndexQuestions", "questions", "id_log_stat_diagnostic"];

			manageLogStatDiagnostic("interruption_diagnostic");

			for (const ls of lsToRemove) {
				localStorage.removeItem(ls);
			}

	
			// Go to Questions page
			this.$router.push({name: 'QuestionsPage'})
		}
	}
}
</script>