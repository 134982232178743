<template>
  <main class="bg-beige page two-cols px-3 md:px-0">
    <div class="scroll" id="scroll-resultat">
      <div class="scroll-container">
        <div class="scroll-indicator"></div>
      </div>
    </div>
	<div class="back-home hidden lg:block">
		<router-link :to="homePath">
			<span class="text-orange cursor-pointer">
				<font-awesome-icon :icon="['fa', 'left-long']" />
				<span class="ml-[8px]">
					Retour à l'accueil
				</span>
			</span>
		</router-link>
	</div>
    <div class="grid grid-cols-12 lg:h-full">

      <!-- Partie résultat -->
      <div class="col-span-12 lg:col-span-7 xl:col-span-7 col-scroll pb-[40px]" data-scroll="scroll-resultat">
        <ResultDetailComponent v-if="recommandation" :recommandation-props="recommandation" :resultats-props="resultats"/>
      </div>

      <!-- Partie formulaire -->
      <div class="col-span-12 lg:col-span-5 xl:col-span-5 fixed-col lg:p-0">
        <ResultFormulaireComponent v-if="recommandation" :recommandation-props="recommandation"/>
      </div>
    </div>

  </main>
</template>

<script>
import config from '@/config';

const MOYENNE_COMBINAISON = 3;

import ResultDetailComponent from '@/components/ResultDetailComponent.vue';
import ResultFormulaireComponent from '@/components/ResultFormulaireComponent.vue';

import manageLogStatDiagnostic from "@/fonctions";

export default {
	name: 'ResultPage',
    data() {
      return {
			resultats: [],
			datas_local_storage: [],
			recommandation : null,
			homePath: config.paths.home
		}
	},
    components: {
		ResultDetailComponent,
		ResultFormulaireComponent,
    },
    methods: {
		//fonction récuperant les données de la base de données nécessaire pour le calculs des scores et l'affichage du front
		async getData() {
			const requestOptions = {
				method: "POST",
				cors: 'no-cors',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*'
				}
			};

			const response = await fetch(config.fetchURL + 'getDataAffichage.php', requestOptions);
			const data = await response.json();

			return data;
		},
		getReponse() {
			//Récupérations des données envoyées par le module question
			this.datas_local_storage = Object.values(JSON.parse(localStorage.getItem("score")));

			this.getData().then((data) => {

				//Tableau servant aux calculs et aux stockage des résulats
				var scores_dimensions = [];
				var recommandation_combinaison = [];
				
				//Récupération des dimension en fonction de leur ordre dans la bdd
				data.dimensions.forEach(dimension => {
					//je récupére les dimensions 1 à 1			
					var score_dimension = this.datas_local_storage.filter(dimension_local_storage => dimension.id_dimension == dimension_local_storage.dimension)[0];
					
					if(score_dimension){
						//Calcul des scores de la dimension récupéré du localStorage 
						var score = (score_dimension.score_total / score_dimension.nb_questions).toFixed(2);
						scores_dimensions.push({ dimension: score_dimension.dimension, score: score, id_note: 0 });
					}
				});

				scores_dimensions.forEach((score_dimension, index) => {
					
					//Récupération des "dimensions"
					var dimension = data.dimensions.filter(dimension => dimension.id_dimension == score_dimension.dimension)[0];
					scores_dimensions[index].nom_dimension = dimension.nom_dimension;
					scores_dimensions[index].description_dimension = dimension.description;
					scores_dimensions[index].titre_resultat = dimension.titre_resultat;

					//Récupération des "notes" en fonction des scores calculé précédement
					var note_score = data.scores.filter(score => parseFloat(score.score_min) <= parseFloat(score_dimension.score)
							&& parseFloat(score.score_max) >= parseFloat(score_dimension.score)
							&& score.id_dimension == score_dimension.dimension
					)[0];
					scores_dimensions[index].id_note = note_score.id_note;

					//Récupération des "notes"
					var note = data.notes.filter(note => note.id_note == note_score.id_note)[0];
					scores_dimensions[index].note = note.valeur;

					//Récupération des "résultats" en fonction des notes récupéré précédement
					var resultat = data.resultats.filter(resultat => resultat.id_dimension == score_dimension.dimension && resultat.id_note == note.id_note)[0];
					scores_dimensions[index].resultat_description = resultat.description;

					//Récupération des "points forts" en fonction des notes récupéré précédement
					var points_forts = data.points_forts.filter(point_fort => point_fort.id_dimension == score_dimension.dimension && point_fort.id_note == note.id_note);
					scores_dimensions[index].points_forts = points_forts;

					// Création d'une matrice pour identifier la recommandation ?
					if (note_score.id_note >= MOYENNE_COMBINAISON) {
						recommandation_combinaison.push(1);
					} else {
						recommandation_combinaison.push(0);
					}
				});
				this.resultats = scores_dimensions;

				var recommandation_finale = data.recommandations.filter(recommandationDB => recommandationDB.combinaison == recommandation_combinaison);
				if (recommandation_finale.length > 0) {
					this.recommandation = recommandation_finale[0];
				} else {
					this.recommandation = data.recommandations[0];
				}

				var id_recommandation = this.recommandation.id_recommandation;

				if (localStorage.getItem("log_stat_completed") === null) {
					manageLogStatDiagnostic("fin_diagnostic", id_recommandation);
				}
			});
		}
	},
    created() {
        var jGetScore = localStorage.getItem("score");
		if (!jGetScore) {
			this.$router.push({ name: "HomePage" });
		} else {
			this.getReponse();
		}
    }
}
</script>
