<template>
    <div v-if="this.showCookieBanner" id="cookie-banner">
		<div class="cookie-wrapper md:flex md:gap-8 md:items-center lg:container">
			<p class="para-text no-margin">En continuant votre navigation, vous acceptez que notre site utilise des cookies afin de vous proposer un diagnostic d'employabilité en accord 
				avec vos choix de réponses. Les informations recueillies dans le questionnaire sont anonymes.  Pour plus d'information, nous vous invitons à 
				consulter notre politique de confidentialité en <a class="cta-text" href="https://www.aksis.fr/politique-de-confidentialite/" target="_blank">cliquant ici</a>.</p>
			<div>
				<button class="btn btn-white mt-[30px] md:mt-0 w-full md:w-max h-max" @click="acceptCookie">
				Continuer la navigation
				</button>
			</div>
		</div>
    </div>
</template>

<script>
  export default {
    name: 'CookieBannerComponent',

	data() {
		return {
			showCookieBanner : true,
		}
	},

	methods: {
		acceptCookie() {
			localStorage.setItem("accept-cookie", true);
			this.showCookieBanner = false;
		}
	},

	created() {
		const acceptCookie = localStorage.getItem("accept-cookie") === "true";

		this.showCookieBanner = !acceptCookie;
	}
  }
</script> 