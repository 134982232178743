const isPreprod =  process.env.NODE_ENV === 'preprod';
const isProd =  process.env.NODE_ENV === 'production';

function getFetchURL() {
    if(isPreprod) {
        return '/employabilite/controllers/'
    }

    if(isProd) {
        return '/controllers/'
    }

    return 'http://localhost/employabilite/employabilite-app/controllers/'
}

export default {
    swalOptions : {
        "toast" : true,
        "position" : "top-right",
        "timer" : "5000",
        "timerProgressBar" : true,
        "confirmButtonText" : "Fermer",
        "confirmButtonColor" : "#ef7d14",
    },
    paths : {
        home : isPreprod ? "/employabilite/" : "/", 
        questions : isPreprod ? "/employabilite/questions" : "/questions", 
        resultat : isPreprod ? "/employabilite/resultat" : "/resultat", 
        template : isPreprod ? "/employabilite/" : "/template", 
        merci : isPreprod ? "/employabilite/merci" : "/merci", 
    },
    fetchURL: getFetchURL(),
}