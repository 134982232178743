import config from '@/config';

async function setDatas(formData) {
    var requestOptions = {
        method: "POST",
        cors: 'no-cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(formData)
    };

    var response = await fetch(config.fetchURL + 'manageLogStatDiagnostic.php', requestOptions);
    var data = response.json();

    return data;
}

function manageLogStatDiagnostic(type_action, id_recommandation = null, type_contact = null) {

    var formData = {
        'type_action': type_action
    }

    var id_log_stat_diagnostic = localStorage.getItem("id_log_stat_diagnostic");
    var log_stat_completed = localStorage.getItem("log_stat_completed");

    switch (type_action) {
        case "debut_diagnostic":
            if (id_log_stat_diagnostic === null) {
                setDatas(formData).then((data) => {
                    var id_log_stat_diagnostic = data.id_log_stat_diagnostic;
                    localStorage.setItem('id_log_stat_diagnostic', id_log_stat_diagnostic);
                });
            }
            break;

        case "fin_diagnostic":
            var score = localStorage.getItem("score");

            formData.id_log_stat_diagnostic = id_log_stat_diagnostic;
            formData.id_recommandation = id_recommandation;
            formData.score = score;

            setDatas(formData).then(() => {

                localStorage.setItem('log_stat_completed', true);
            });
            break;

        case "interruption_diagnostic":

            formData.id_log_stat_diagnostic = id_log_stat_diagnostic;

            if (log_stat_completed === null) {
                setDatas(formData);
            }
            localStorage.removeItem('log_stat_completed');
            break;

        case "contact_diagnostic":
            formData.id_log_stat_diagnostic = id_log_stat_diagnostic;
            formData.type_contact = type_contact;

            setDatas(formData);
            break;
    }
}

export default manageLogStatDiagnostic;