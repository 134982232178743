<template>
	<div class="w-full justify-center container-formulaire px-5 xl:px-[35px] 2xl:px-[50px]" id="js-container-formulaire">

		<!-- Footer -->
		<div class="w-full flex lg:hidden justify-center">
			<div class="max-w-[400px] w-full">
				<div class="w-full flex justify-around space-x-4">
					<button target="_blank" v-if="this.hasForm" class="btn btn-primary lg:mt-5" @click="triggerFormMobile">
						Faire un {{ this.recommandation.titre }}
					</button>
					<a v-else :href="this.recommandation.lien_cta" target="_blank" class="btn btn-primary" @click="clickLienRecommandation()">
							Je prends contact
					</a>
					<button class="btn btn-yellow h-[54px] w-[54px] bg-yellow lg:mt-5" @click="scrollToEl('recommandation-mobile')">
						?
					</button>
				</div>
			</div>
		</div>

		<div class="w-full hidden lg:block">
			<div class="mx-auto">
				<!-- Recommandation Desktop -->
				<div class="w-full">
					<div class="container-recommandation mt-5 mb-[30px]">
						<div class="illu-img illu-fleche-yellow hidden xl:block">
							<img src="../assets/img/illu/arrow-yellow-1.png" alt="Flèche jaune" />
						</div>
						<div class="illu-img illu-fleche-orange hidden xl:block">
							<img src="../assets/img/illu/arrow-orange-1.png" alt="Flèche orange" />
						</div>
						<div class="grid grid-cols-12 items-end gap-3 xl:gap-[25px]">
							<div class="hidden xl:block xl:col-span-4">
								<div class="bloc-image bloc-image-taller bloc-yellow image-left-align" style="--i : 1">
									<img src="../assets/img/photos/aksis_bdc_femme1.png" alt="Femme pensive" />
								</div>
							</div>
							<div class="col-span-12 xl:col-span-8">
								<h3 class="font-bold">Notre recommandation pour&nbsp;vous</h3>
								<h4 class="titre-recommandation text-blue">{{ this.recommandation.titre }}</h4>
								<!-- <p class="para-text xl:mb-3 2xl:mb-5">{{ this.recommandation.description }}</p> -->
								<p class="para-text truncate-text no-margin mb-2" :data-expend="this.isRecommandationDeroule">{{ this.recommandation.description }}</p>
								<p class="">
									<a class="cta-text cursor-pointer" @click="deroulerRecommandation">{{ this.isRecommandationDeroule ? "Afficher moins" : "Lire la suite" }}</a>
								</p>
							</div>
						</div>
					</div>
					<div v-if="bShowLinkRecommandation && this.hasForm" class="mb-[35px]">
						<a :href="this.recommandation.lien_cta" class="cta-text" target="_blank">{{ this.recommandation.description_lien_cta }} {{ this.recommandation.titre }}</a>
					</div>
					<div v-if="!this.hasForm">
						<p class="para-text">{{ this.recommandation.description_lien_cta }} {{ this.recommandation.titre }}</p>
						<a :href="this.recommandation.lien_cta" target="_blank" class="btn btn-primary mt-[35px]" @click="clickLienRecommandation()">
							Je prends contact
						</a>
					</div>

				</div>
			</div>
		</div>
		<!-- Formulaire Desktop -->
		<div class="fixed lg:static bottom-0 left-0 z-100 bg-white w-full p-[22.5px] lg:p-0 border-top-style space-y-[25px] mobile-form"
			:class="{'mobile-form-show': bShowFormMobile}">
			<div class="w-full lg:hidden max-w-[500px] lg:max-w-[inherit] mx-auto">
				<span class="text-orange cursor-pointer" @click="triggerFormMobile">
					<font-awesome-icon :icon="['fa', 'left-long']" />
					<span class="ml-[8px]">
						Retour aux résultats
					</span>
				</span>
			</div>
			<div class="w-full flex justify-center">
				<div v-if="hasForm" class="w-full max-w-[500px] lg:max-w-[inherit]">
					<form class="grid grid-cols-12 gap-3 md:gap-5" id="form-contact">
						<div class="col-span-12 xl:col-span-6" id="js-row-nom">
							<label for="nom_contact" class="form-label">Votre nom*</label>
							<div :class="'form-row ' +  inputNom.class ">
								<input 
								name="nom_contact" 
								type="text" 
								v-model="inputNom.value" 
								class="form-input" />
								<div class="form-picto">
									<font-awesome-icon :icon="['far', 'user']" />
								</div>
							</div>
						</div>
						<div class="col-span-12 xl:col-span-6" id="js-row-prenom">
							<label for="prenom_contact" class="form-label">Votre prénom*</label>
							<div :class="'form-row ' + inputPrenom.class">
								<input 
								name="prenom_contact"
								type="text" 
								v-model="inputPrenom.value" class="form-input" />
								<div class="form-picto">
									<font-awesome-icon :icon="['far', 'user']" />
								</div>
							</div>
						</div>
						<div class="col-span-12 xl:col-span-6" id="js-row-email">
							<label for="email_contact" class="form-label">Votre adresse email*</label>
							<div :class="'form-row ' +  inputEmail.class">
								<input 
								name="email_contact" 
								type="text" 
								v-model="inputEmail.value" 
								class="form-input" />
								<div class="form-picto">
									<font-awesome-icon :icon="['far', 'envelope']" />
								</div>
							</div>
						</div>
						<div class="col-span-12 xl:col-span-6" id="js-row-tel">
							<label for="tel_contact" class="form-label">Votre téléphone*</label>
							<div :class="'form-row ' +  inputTel.class">
								<input 
								name="tel_contact" 
								type="text" 
								v-model="inputTel.value" 
								class="form-input" />
								<div class="form-picto">
									<font-awesome-icon :icon="['fa', 'phone-alt']" />
								</div>
							</div>
						</div>
						<div class="col-span-12 xl:col-span-6" id="js-row-cp">
							<label for="cp_contact" class="form-label">Votre code postal*</label>
							<div :class="'form-row ' +  inputCP.class">
								<input 
								name="cp_contact" 
								type="text" 
								v-model="inputCP.value" 
								class="form-input" />
								<div class="form-picto">
									<font-awesome-icon :icon="['fas', 'location-dot']" />
								</div>
							</div>
						</div>
						<div class="col-span-12 xl:col-span-6" id="js-row-ville">
							<label for="ville_contact" class="form-label">Votre ville*</label>
							<div :class="'form-row ' +  inputVille.class">
								<input 
								name="ville_contact" 
								type="text" 
								v-model="inputVille.value" 
								class="form-input" />
								<div class="form-picto">
									<font-awesome-icon :icon="['fas', 'location-dot']" />
								</div>
							</div>
						</div>
						<div class="col-span-12">
							<label class="form-label" for="message_user">Votre message</label>
							<div  :class="'form-row row-textarea ' + inputMessage.class">
								<textarea 
								name="message_user"
								class="form-input" 
								v-model="inputMessage.value" 
								cols="30" rows="10"></textarea>
								<div class="form-picto">
									<font-awesome-icon :icon="['far', 'message']" />
								</div>
							</div>
						</div>
						<div class="mt-5 col-span-12" id="js-row-checkbox">
							<label class="form-checkbox" for="conditions-form">
								<input type="checkbox" v-model="inputCheckbox.value" id="conditions-form">
								<div>
									<svg class="form-checkbox-check" viewBox="-2 -2 35 35" aria-hidden="true">
										<polyline points="7.57 15.87 12.62 21.07 23.43 9.93" />
									</svg>
								</div>
								<div class="form-label select-none">J'accepte que mes données soient transmises au Groupe Aksis Moovéus. 
									Pour en savoir plus, <a class="cta-text cursor-pointer" @click.prevent="toggleModalCGU">cliquez&nbsp;ici</a>.</div>
							</label>
						</div>
					</form>
					<div class="w-full flex justify-center">
						<button :class="'btn btn-primary mt-[25px]' + (this.isButtonLoading ? ' loading-button ' : '')" @click="sendFormulaire">
							Réaliser un {{ formatButtonLabel }}
						</button>
					</div>
				</div>
			</div>
		</div>

		<modal-component v-if="this.showModalCGU" @closeModal="toggleModalCGU" :title-modal="'Conditions d\'utilisation de vos données'" :has-footer="true">
			<template #modalBody>
				<p class="para-text">Le Groupe Aksis Moovéus s'engage à assurer le meilleur niveau de protection de vos données personnelles 
					en conformité avec les réglementations européennes et françaises qui lui sont applicables.</p> 
				<p class="para-text">Pour toute information sur la 
					protection de vos données personnelles, nous vous invitons à consulter notre politique de confidentialité en 
					<a class="cta-text" :href="this.pdcRecommandation" target="_blank">cliquant ici</a></p>
			</template>
			<template #modalFooter>
				<div class="flex justify-center">
						<button class="btn btn-primary" @click="toggleModalCGU">
						J'ai compris
						</button>
					</div>
			</template>
		</modal-component>
		<peoples-image-component v-if="!hasForm && !this.isRecommandationDeroule" />
	</div>
</template>

<script>
import config from '@/config';

import manageLogStatDiagnostic from "@/fonctions";

import PeoplesImageComponent from '@/components/PeoplesImageComponent.vue';
import ModalComponent from '@/components/ModalComponent.vue';

import { ref } from 'vue';

export default {
	name: 'ResultDetail',
	props: {
		recommandationProps: Object,
	},

	data() {
		return {
			inputPrenom : {
				value : ref(""),
				class : "",
				isValid : null,
			},
			inputNom : {
				value : ref(""),
				class : "",
				isValid : null,
			},
			inputEmail : {
				value : ref(""),
				class : "",
				isValid : null,
			},
			inputCP : {
				value : ref(""),
				class : "",
				isValid : null,
			},
			inputTel : {
				value : ref(""),
				class : "",
				isValid : null,
			},
			inputVille : {
				value : ref(""),
				class : "",
				isValid : null,
			},
			inputMessage : {
				value : ref(""),
				class : "",
				isValid : null,
			},
			inputCheckbox : {
				value : ref(false)
			},
			recommandation : this.recommandationProps,
			hasForm: this.recommandationProps.mail_contact ? true : false,
			bShowLinkRecommandation: this.recommandationProps.lien_cta ? true : false,
			bShowFormMobile : false,
			newListSend:{

			},
			isRecommandationDeroule: false,
			isButtonLoading : false,
			showModalCGU: false,
			pdcRecommandation: this.recommandationProps.lien_pdc

		}
	},
	components: {
        PeoplesImageComponent,
		ModalComponent,
	},

	methods: {
		toggleModalCGU() {
			this.showModalCGU = !this.showModalCGU;
		},
		scrollToEl(targetID) {
			const target = document.getElementById(targetID);

			if(!target) return;

			target.scrollIntoView({behavior: 'smooth'});
			target.classList.add("shake-anim");
			setTimeout(() => {
				target.classList.remove("shake-anim");
			}, 500)
		},
		deroulerRecommandation() {
			this.isRecommandationDeroule = !this.isRecommandationDeroule;
			var scollContainer = document.getElementById('js-container-formulaire');
			scollContainer.scrollTop = 0;
		},
		triggerFormMobile() {
			this.bShowFormMobile = !this.bShowFormMobile;
		},
		async getData() {
			const formData = {
				'nom': this.inputNom.value,
				'prenom': this.inputPrenom.value,
				'adresse_mail': this.inputEmail.value,
				'telephone': this.inputTel.value,
				'code_postal': this.inputCP.value,
				'ville': this.inputVille.value,
				'message': this.inputMessage.value,
				'mail_sujet': this.recommandation.mail_sujet,
				'mail_contact': this.recommandation.mail_contact,
				'id_recommandation': this.recommandation.id_recommandation
			};
			const requestOptions = {
				method: "POST",
				cors: 'no-cors',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*'
				},
				body: JSON.stringify(formData)
			};

			const response = await fetch(config.fetchURL + 'sendMailController.php', requestOptions);
			const data = response.json();

			return data;

		},
		sendFormulaire(){
			/**
			 * Gestion du statut du bouton, loading ou non
			 */
			if(this.isButtonLoading) {
				return
			}

			this.isButtonLoading = true;

			/**
			 * Vérification des champs obligatoires
			 */
			const champsObligatoires = [
				{model: "inputNom", nom: "nom", format: "Ce champ ne doit pas contenir de chiffre", domTarget: document.getElementById("js-row-nom") },
				{model: "inputPrenom", nom: "prénom", format: "Ce champ ne doit pas contenir de chiffre", domTarget: document.getElementById("js-row-prenom") },
				{model: "inputEmail", nom: "email", format: "Ce champ doit respecter le format exemple@email.com", domTarget: document.getElementById("js-row-email") },
				{model: "inputTel", nom: "téléphone", format: "Ce champ doit respecter le format 06 07 08 09 10 ou  +33607080910", domTarget: document.getElementById("js-row-tel") },
				{model: "inputCP", nom: "code postal", format: "Ce champ ne doit pas contenir de lettre", domTarget: document.getElementById("js-row-cp") },
				{model: "inputVille", nom: "ville", format: "Ce champ ne doit pas contenir de chiffre", domTarget: document.getElementById("js-row-ville") },
			]
		
			for (const champ of champsObligatoires) {
				let alerte = null;

				if(this[champ.model].value.trim() == "") {
					alerte = `Le champ ${champ.nom} est obligatoire. Veuillez le remplir.`;
				}

				if(this[champ.model].isValid === false) {
					alerte = `Le format du champ ${champ.nom} est incorrect. ${champ.format}`;
				}

				
				if(alerte !== null) {
					this.$swal({
						"text": alerte, ...config.swalOptions
					})
					this.isButtonLoading = false;
					this.shakeAnim(champ.domTarget);
					return
				}
            }
			
			if(!this.inputCheckbox.value) {
				let alerte = `Veuillez accepetez les conditions d'utilisations de vos données.`;

				this.$swal({
					"text": alerte, ...config.swalOptions
				})
				this.isButtonLoading = false;
				this.shakeAnim(document.getElementById("js-row-checkbox"));
				return
			}

			this.getData().then((data) => {
				this.isButtonLoading = false;
				
				if (data.notifSend) {
					this.goToThanks();

					manageLogStatDiagnostic("contact_diagnostic", null, "formulaire");
				} else {
					this.$swal({
						"text": data.error['message'], ...config.swalOptions
					})
				}
			})
		},
		shakeAnim(target) {
			target.classList.add("shake-anim");
			setTimeout(() => {
				target.classList.remove("shake-anim");
			}, 500)
		},
		checkFormatInput(value, type) {

			if(type === "tel") return /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(value)

			if(type === "number") return !this.hasText(value);

			if(type === "text") return !this.hasNumber(value);

			if(type === "email") return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);

			return false;

		},

		hasNumber(value) {
			return /\d/.test(value);
		},

		hasText(value) {
			return /\D/.test(value);
		},
		goToThanks() {
			this.$router.push({ name: 'PageRemerciement' })
		},
        clickLienRecommandation() {
			var hrefBouton = this.recommandation.lien_cta;
			
			var type_contact = "";

			switch (hrefBouton) {
				case "https://www.mooveus.fr":
					type_contact = "lien_recommandation_mooveus";
					break;
				case "https://www.aksis.fr/faire-un-bilan-de-competences/":
					type_contact = "lien_recommandation_aksis";
					break;
			}
			
			manageLogStatDiagnostic("contact_diagnostic", null, type_contact);
        }
	},
	computed: {
		formatButtonLabel() {
			return this.recommandation.titre.charAt(0).toLowerCase() + this.recommandation.titre.slice(1);
		}
	},
	watch : {
		'inputPrenom.value'(value) {
			if(value === "") {
				this.inputPrenom.class = "";
				this.inputPrenom.isValid = null;
				return
			}

			this.inputPrenom.isValid = this.checkFormatInput(value, "text");
			this.inputPrenom.class = this.inputPrenom.isValid ? " form-success" : " form-error";

		},
		'inputNom.value'(value) {

			if(value === "") {
				this.inputNom.class = "";
				this.inputNom.isValid = null;
				return
			}
			
			this.inputNom.isValid = this.checkFormatInput(value, "text");
			this.inputNom.class = this.inputNom.isValid ? " form-success" : " form-error";
		},

		'inputEmail.value'(value) {

			if(value === "") {
				this.inputEmail.class = "";
				this.inputEmail.isValid = null;
				return
			}

			this.inputEmail.isValid = this.checkFormatInput(value, "email");
			this.inputEmail.class = this.inputEmail.isValid ? " form-success" : " form-error";

		},

		'inputCP.value'(value) {

			if(value === "") {
				this.inputCP.class = "";
				this.inputCP.isValid = "";
				return
			}

			this.inputCP.isValid = this.checkFormatInput(value, "number");
			this.inputCP.class = this.inputCP.isValid ? " form-success" : " form-error";

		},

		'inputVille.value'(value) {

			if(value === "") {
				this.inputVille.class = "";
				this.inputVille.isValid = null;
				return
			}

			this.inputVille.isValid = this.checkFormatInput(value, "text");
			this.inputVille.class = this.inputVille.isValid ? " form-success" : " form-error";

		},

		'inputTel.value'(value) {

			if(value === "") {
				this.inputTel.class = "";
				this.inputTel.isValid = null;
				return
			}

			this.inputTel.isValid = this.checkFormatInput(value, "tel");
			this.inputTel.class = this.inputTel.isValid ? " form-success" : " form-error";

		},

		'inputMessage.value'(value) {

			if(value === "") {
				this.inputMessage.class = "";
				this.inputMessage.isValid = null;
				return
			}

			this.inputMessage.class = " form-success";
			this.inputMessage.isValid = true;
		}
	},
}
</script>

