export default {
    initApp : () => {
        initScrollListener();
    }
}

// Gestion de la barre de progression horizontale au scroll
const initScrollListener = () => {
    const scrollListener = document.querySelectorAll("[data-scroll]");

    [].forEach.call(scrollListener, function(el) {
        initScrollBar(el);
        el.removeEventListener('scroll', listenScrollBarDesktop)
        el.addEventListener('scroll', listenScrollBarDesktop)
    })

}

const initScrollBar = (el) => {
    var scrollIndicator = el.querySelector(".scroll-indicator")
    if(scrollIndicator !== null) scrollIndicator.style.width = 0+"%";
}

const listenScrollBarDesktop = (e) => {
    var el = e.target;
    let dataScroll = el.dataset.scroll;
    const scrollTarget = document.getElementById(dataScroll);
    setScrollBar(el, scrollTarget);
}

const setScrollBar = (el, target) => {
    var scrollWrapper = el.querySelector(".scroll-wrapper")
    var scrollIndicator = target.querySelector(".scroll-indicator");

    if(scrollWrapper == null || scrollIndicator == null) return

    var heightParent = el.offsetHeight;
    var height = scrollWrapper.offsetHeight;
    var delta = height - heightParent;
    var scrollTop = el.scrollTop;
    var percent = (scrollTop * 100) / delta

    scrollIndicator.style.width = percent+"%";
}