<template>
    <main class="bg-beige page two-cols px-3 md:px-0">
        <div class="scroll" id="scroll-homepage">
            <div class="scroll-container">
                <div class="scroll-indicator"></div>
            </div>
        </div>
        <div class="grid grid-cols-12 lg:h-full">
            <div class="col-span-12 lg:col-span-7 xl:col-span-7 col-scroll" data-scroll="scroll-homepage">
                <div class="scroll-wrapper py-5 lg:pt-0">
                    <h1 class="title-h1">Votre Diagnostic d’employabilité</h1>
                    <p class="para-text">L’employabilité, vous en avez forcément entendu parler. Ce terme un peu bizarre
                        désigne en réalité la capacité à piloter efficacement son parcours professionnel. L’employabilité d’une
                        personne est forte si, par exemple, cette personne sait préparer les prochaines étapes de son parcours
                        professionnel, si elle sait repérer les formations dont elle a besoin pour progresser et si elle sait rebondir
                        rapidement en cas de difficultés. Conclusion&nbsp;? </p>
                    <p class="para-text catch-line font-bold no-margin mb-3">Nous avons tous besoin de connaître où en est notre
                        employabilité&nbsp;!</p>

                    <div class="grid grid-cols-12 items-end gap-3 mb-[40px] max-w-[650px] select-none">
                        <div class="col-span-5 md:col-span-4">
                            <div class="bloc-image bloc-yellow" style="--i : 1">
                                <img src="../assets/img/photos/aksis_bdc_homme1.png" alt="Homme joyeux" />
                            </div>
                        </div>
                        <div class="col-span-7 md:col-span-4">
                            <div class="bloc-image bloc-orange" style="--i : 2">
                                <img src="../assets/img/photos/aksis_bdc_femme3.png" alt="Femme heureuse" />
                            </div>
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <div class="bloc-image bloc-blue" style="--i : 3">
                                <img src="../assets/img/photos/aksis_bdc_homme2.png" alt="Homme pensif" />
                            </div>
                        </div>
                    </div>

                    <p class="para-text">Répondez à ces quelques questions. Il ne vous faudra pas plus de quelques
                        minutes&nbsp;! </p>
                    <p class="para-text">Les résultats vous permettront de mieux comprendre où en est, aujourd’hui,
                        votre
                        employabilité. Nous vous proposerons ensuite quelques actions à mener pour aller plus loin.</p>
                    <p class="para-text">Le Diagnostic Employabilité a été conçu pour Aksis par la Chaire Compétences et
                        Employabilité de l’Ecole de Management de Normandie.</p>
                    <h2 class="title-h3 lg:my-[50px]">Comment passer le diagnostic&nbsp;?</h2>
                    <p class="para-text">Ce questionnaire vous présente une série d’affirmations. À vous d’indiquer
                        votre degré
                        d’accord avec chacune d’elles en cochant de «&nbsp;Pas du tout d’accord&nbsp;» à «&nbsp;Tout fait d’accord&nbsp;».
                    </p>
                    <p class="para-text">Choisissez la réponse qui vous correspond le mieux, qui est la plus proche de
                        ce que vous
                        pensez être.</p>

                    <div class="grid grid-cols-12 gap-3 mb-[35px] select-none">
                        <div class="col-span-6 sm:col-span-4 lg:col-span-4">
                            <img src="../assets/img/photos/perso-1.png" style="--i : 1" class="max-h-[180px] img-scale-in mx-auto"
                                alt="Femme utilisant son téléphone" />
                        </div>
                        <div class="col-span-6 sm:col-span-4 lg:col-span-4">
                            <img src="../assets/img/photos/perso-2.png" style="--i : 2" class="max-h-[180px] img-scale-in mx-auto"
                                alt="Homme souriant" />
                        </div>
                    </div>

                    <p class="para-text">Il n’y a pas de bonnes ou mauvaises réponses, soyez spontané, c’est la
                        meilleure façon
                        d’avoir des résultats fiables. L'authenticité de vos réponses garantit la pertinence de vos
                        résultats.</p>
                    <p class="para-text">Répondez en vous situant dans le contexte professionnel. </p>
                    <p class="para-text mb-[40px]">Votre temps n'est pas limité, mais il est important que vous répondiez de
                        manière continue, sans vous interrompre.</p>
                </div>
            </div>
            <div class="col-span-12 lg:col-span-5 xl:col-span-5 fixed-col lg:pt-0 lg:-mt-[15px]">
                <div class="w-full hidden lg:flex justify-center lg:px-5">
                    <div class="w-full min-h-[200px] h-full rounded-[4px] overflow-hidden border-blue max-w-[450px] max-h-[30vh] mb-[35px] ">
                        <img src="../assets/img/photos/employabilite-design.png" class="w-full object-cover h-full anim-opacity" style="--i : 1" alt="Employabilité Aksis" />
                    </div>
                </div>
                <div class="w-full flex justify-center">
                    <div class="anim-opacity" style="--i : 2">
                        <div class="flex justify-center flex-wrap gap-5 lg:mt-5" v-if="!this.isDiagnosticFinished">
                            <router-link :to="pathQuestions" >
                                <button class="btn btn-primary">
                                    Je {{ isDiagnosticStarted ? 'continue' : 'commence' }} mon diagnostic
                                </button>
                            </router-link>
                            <div v-if="this.isDiagnosticStarted">
                                <router-link :to="pathQuestions">
                                    <button class="btn btn-primary" @click="recommencerTest">
                                        Recommencer le test
                                    </button>
                                </router-link>
                            </div>
                        </div>
                        <div class="flex justify-center flex-wrap gap-5 lg:mt-5" v-else>
                            <router-link :to="pathResultats" >
                                <button class="btn btn-primary ">
                                    Voir mes résultats
                                </button>
                            </router-link>
                            <router-link :to="pathQuestions">
                                <button class="btn btn-primary" @click="recommencerTest">
                                    Recommencer le test
                                </button>
                            </router-link>

                        </div>
                    </div>
                </div>
                <peoples-image-component />
            </div>
        </div>

    </main>
</template>

<script>
import PeoplesImageComponent from '@/components/PeoplesImageComponent.vue';
import config from '@/config';

import manageLogStatDiagnostic from "@/fonctions";

export default {
    name: 'HomePage',
    data() {
        return {
            nbQuestions : 30,
            isDiagnosticStarted : false,
            isDiagnosticFinished : false,
            pathQuestions : config.paths.questions,
            pathResultats : config.paths.resultat,
        }
    },
    components: {
        PeoplesImageComponent,
    },
    methods: {
        recommencerTest() {
            const lsToRemove = ["score", "answers", "numberPagesCompleted", "randomIndexQuestions", "questions", "id_log_stat_diagnostic"];
            
            manageLogStatDiagnostic("interruption_diagnostic");
            
            for (const ls of lsToRemove) {
                localStorage.removeItem(ls);
            }
        }
    },
    created() {
        this.isDiagnosticStarted = localStorage.getItem("numberPagesCompleted") !== null && localStorage.getItem("numberPagesCompleted") !== undefined;
        this.isDiagnosticFinished = localStorage.getItem("numberPagesCompleted") == this.nbQuestions;
    }
}
</script>

<style>
    .capture-page {
        border: 2px solid var(--blue);
        border-radius: 2px;
        position: relative;
    }

    .capture-mobile {
        position: absolute;
        right: 0;
        top : 65px;
    }
</style>