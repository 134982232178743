<template>
    <div class="bg-beige min-w-screen min-h-screen md:px-[35px]">
        <div class="lg:container mx-auto">
            <header-component />
            <div class="pt-[100px] lg:pt-[35px] px-5 lg:px-0">
                <router-view />
            </div>
            <cookie-banner-component />
        </div>
    </div>
</template>

<script>
import '../src/assets/css/style.css';
import HeaderComponent from './components/HeaderComponent.vue';
import CookieBannerComponent from './components/CookieBannerComponent.vue';
var appJs = require("./assets/js/app.js");

export default {
    name: 'App',
    components: {
        HeaderComponent,
        CookieBannerComponent
    },
    data() {
        return {
            windowWidth: window.innerWidth
        }
    },

    methods: {
        onResize() {
            this.windowWidth = window.innerWidth
        },

    },

    mounted() {
        // Gestion des sections avec scroll
        this.$nextTick(() => {
            appJs.default.initApp();
            window.addEventListener('resize', this.onResize);
        })
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },

    watch: {
        $route() {
            this.$nextTick(() => {
                appJs.default.initApp();
            })
        }
    }
}
</script>

