<template>
  <div class="w-full mt-[15px] px-2 md:px-0 lg:text-center">
      <h2 class="title-h2">Oups... Cette page n'existe pas&nbsp;!</h2>
      <router-link :to="pathHome">
          <p class="cta-text mb-3 lg:mb-[50px]" target="_blank">Retour à l'accueil</p>
      </router-link>
      <div class="w-full flex justify-center">
          <div class="grid w-full max-w-[1000px] grid-cols-12 items-end gap-3 mb-3">
              <div class="col-span-5 lg:col-span-4">
                  <div class="bloc-image bloc-yellow" style="--i : 1">
                      <img src="../assets/img/photos/aksis_bdc_homme1.png" alt="Homme joyeux" />
                  </div>
              </div>
              <div class="col-span-7 lg:col-span-4">
                  <div class="bloc-image bloc-orange" style="--i : 2">
                      <img src="../assets/img/photos/aksis_bdc_femme3.png" alt="Femme heureuse" />
                  </div>
              </div>
              <div class="col-span-12 lg:col-span-4">
                  <div class="bloc-image bloc-blue" style="--i : 3">
                      <img src="../assets/img/photos/aksis_bdc_homme2.png" alt="Homme pensif" />
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
  import config from '@/config';

  export default {
      name: '404Page',
      data() {
          return {
              pathHome : config.paths.home,
          }
      }
  }
</script>